import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactSwipe from 'react-swipe';
import { ImgContainer, Pagination, PaginationDot, BackgroundColor, InfoContainer, Button, CenteredContainer, PreviousButton, NextButton, ArrowContainer, SliderContainer, SlideContainer, ArrowLeftVector, ArrowRightVector, LinkContainer, Icon } from './styles';
import LinkIcon from './link.svg'
import { Link, useNavigate, useParams } from 'react-router-dom';
import useDataStore from '../../services/DataService';
import { AppContentContainer } from '../../styles';
import { useTrackingStore } from '../../services/TrackingService';
import SliderImage from './SliderImage';
import SliderVideo from './SliderVideo';

export default function Project({tag}) {
  const projects = useDataStore(state => state.projects);

  const identifier = useParams().id;
  // const project = projects.find(p => p.identifier === identifier);
  const project = useMemo(()=>{
    return projects.find(p => p.identifier === identifier);
  }, [projects, identifier]);

  const [mediaIndex, setMediaIndex] = useState(0);
  const reactSwipeEl = useRef();
  const videoRefs = useRef([]);

  const navigate = useNavigate();

  const nextImg = () => { reactSwipeEl.current.next() };
  const prevImg = () => { reactSwipeEl.current.prev() };

  const onSlideChange = () => {
    setMediaIndex(reactSwipeEl.current.getPos());
  };

  const onPaginationClick = index => {
    reactSwipeEl.current.slide(index, 800);
    setMediaIndex(index);
  }

  const clickPreviousProject = ()=>{
    const filteredProjects = projects.filter(p => p.tags.split(' ').findIndex(t => t === tag) !== -1);
    const currentIndex = filteredProjects.findIndex(fp => fp.identifier === project.identifier);
    const nextIndex = (currentIndex - 1 + filteredProjects.length) % filteredProjects.length;

    const identifier = filteredProjects[nextIndex].identifier;
    const url = `/${tag}/${identifier}`;
    useTrackingStore.getState().trackPageView(url, identifier);
    navigate(url);
  };
  
  const clickAllProjects = ()=>{
    const url = `/${tag === 'home' ? '' : tag}`;
    useTrackingStore.getState().trackPageView(url, tag);
    navigate(url);
  };

  const clickNextProject = ()=>{
    const filteredProjects = projects.filter(p => p.tags.split(' ').findIndex(t => t === tag) !== -1);
    const currentIndex = filteredProjects.findIndex(fp => fp.identifier === project.identifier);
    const nextIndex = (currentIndex + 1) % filteredProjects.length;

    const identifier = filteredProjects[nextIndex].identifier;
    const url = `/${tag}/${identifier}`;
    useTrackingStore.getState().trackPageView(url, identifier);
    navigate(url);
  };

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, project.videos.length);
  }, [project]);

  useEffect(() => {
    setMediaIndex(0);
  }, [project.identifier]);

  const media = useMemo(()=>{
    const res = project.images.map(i => {return {url: i, type: 'image'}});
    if (project.videos.length > 0) {
      const indexes = project.videos.map(v => v.description !== '' ? parseInt(v.description) : 0);
      for (let index = 0; index < indexes.length; index++) {
        const insertAt = indexes[index];
        res.splice(insertAt, 0, {url: project.videos[index].url, type: 'video', videoIndex: index});
      }
    }
    return res;
  }, [project]);

  const sliderContinuos = useMemo(()=>media.length > 2, [media]);

  const swipeOptions = useMemo(() => ({
    transitionEnd: onSlideChange, 
    continuous: sliderContinuos, 
    speed: 800, 
    auto: project.videos.length > 0 ? 0 : 4000
  }), [project, sliderContinuos]);

  useEffect(() => {
    for (let i = 0; i < media.length; i++) {
      if (media[i].type === 'video') {
        if (i === mediaIndex) {
          videoRefs.current[media[i].videoIndex].play();
        } else {
          videoRefs.current[media[i].videoIndex].pause();
          videoRefs.current[media[i].videoIndex].currentTime = 0;
        }
      }
    }
  }, [mediaIndex, media]);

  const renderLinks = links => {
    // console.log('links', links);
    return links.map((link, i)=>(
      <Link to={link.url} target='_blank' key={i} style={{marginRight: 12}}>
        <Icon src={LinkIcon} alt='link-icon' /> {link.text}
      </Link>
    ))
  };

  const disableRightArrow = !sliderContinuos && mediaIndex >= media.length-1;
  const disableLeftArrow = !sliderContinuos && mediaIndex <= 0;

  return (
    <>
      {project.background_color && <BackgroundColor color={project.background_color}/>}
      <AppContentContainer key={project.identifier}>
        <ImgContainer fullscreen={project.simple}>
          <SliderContainer>
            <ReactSwipe swipeOptions={swipeOptions} ref={reactSwipeEl}>
              {media.map((media, index) => (
                <SlideContainer key={index}>
                  {media.type === 'image' && <SliderImage url={media.url} alt={project.title + 'image ' + (index+1)} />}
                  {media.type === 'video' && <SliderVideo url={media.url} ref={el => videoRefs.current[media.videoIndex] = el}/>}
                </SlideContainer>
              ))}
            </ReactSwipe>
          </SliderContainer>
          {media.length > 1 && !project.simple && (
            <>              
              <ArrowContainer>
                {!disableLeftArrow && (
                  <PreviousButton onClick={prevImg}>
                    <div className='arrow-wrapper'> <ArrowLeftVector size={20} strokeWidth={1}/></div>
                  </PreviousButton>
                )}
                {!disableRightArrow && (
                  <NextButton onClick={nextImg}>
                    <div className='arrow-wrapper'> <ArrowRightVector size={20} strokeWidth={1}/></div>
                  </NextButton>
                )}
              </ArrowContainer>
              <Pagination key={project.identifier}>
                <ArrowLeftVector onClick={prevImg} size={9} strokeWidth={1} disabled={disableLeftArrow} />
                {media.map((img, index) => <PaginationDot key={index} active={index === mediaIndex} onClick={() => {onPaginationClick(index)}} />)}
                <ArrowRightVector onClick={nextImg} size={9} strokeWidth={1} disabled={disableRightArrow}/>
              </Pagination>
            </>
          )}
        </ImgContainer>
        {!project.simple &&
          <InfoContainer>
            <CenteredContainer>
              <h1>{project.title}</h1>
              <div style={{marginBottom: 40}}>
                <p dangerouslySetInnerHTML={{__html: project.text}} />
                {project.links.length > 0 && (
                  <LinkContainer>
                    {renderLinks(project.links)}
                  </LinkContainer>
                )}
              </div>
              <Button onClick={clickPreviousProject}>&lt; last project</Button>
              <Button onClick={clickAllProjects}>all</Button>
              <Button onClick={clickNextProject}>next project &gt;</Button>
            </CenteredContainer>
          </InfoContainer>
        }
      </AppContentContainer>
    </>
  );
}
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useNavigationStore, { menuIcons, menuLinks } from '../../services/NavigationService';
import {  MenuContainer, MenuItem, LogoContainer, IconContainer, Icon, BurgerMenu, BurgerBubble } from './styles';
import LogoDesktop from './LogoDesktop.svg';
import LogoMobile from './LogoMobile.svg';

import { useWindowStore } from '../../services/WindowService';
import { getPathAndId } from '../../utilities/utilities';
import { useTrackingStore } from '../../services/TrackingService';

export default function Menu() {
  const isMobile = useWindowStore(state => state.isMobile);
  const mobileMenuOpen = useNavigationStore(state => state.mobileMenuOpen);

  const {pathname} = useLocation();
  const {path, id} = getPathAndId(pathname);

  const renderMenu = () => (
      <div style={{ width: "100%", float: "right"}}>
        {menuLinks.map((ml, i) => (
          <MenuItem underline={ml.url === '/'+path} index={i} key={i}>
            <Link to={ml.url} onClick={()=>{useTrackingStore.getState().trackPageView(ml.url, ml.title)}}>
              {ml.title}
            </Link>
          </MenuItem>)
        )}
      </div>
    );
 
  const renderIcons = ()=> {
    return (
      <IconContainer>
        {menuIcons.map((icon, key) =>
            <Link to={icon.link} key={key} target='_blank' onClick={()=>{useTrackingStore.getState().trackPageView(icon.link)}}>
              <Icon src={icon.img} alt={icon.imgAltText} />
            </Link>
        )}
        <div style={{marginTop: 23}}>
          <Link to="/impressum" onClick={()=>{useTrackingStore.getState().trackPageView("/impressum", 'impressum')}}>legal notice</Link>
        </div>
      </IconContainer>
    );
  };

  const bubbleInHeader = id !== undefined;

  return (
    <>
      <MenuContainer>
        <LogoContainer>
          <Link to='/' onClick={()=>{useTrackingStore.getState().trackPageView("/", 'home')}}>
            <img src={isMobile ? LogoMobile : LogoDesktop} alt="Logo" />
            {/* {<Logo color={colors.main} width={70} />} */}
          </Link>
        </LogoContainer>
        {!isMobile && renderMenu()}
        {!isMobile && renderIcons()}
      </MenuContainer>
      {isMobile && (
        <BurgerBubble inHeader={bubbleInHeader} onClick={()=>{useNavigationStore.getState().setMobileMenuOpen(!mobileMenuOpen)}}>
          <BurgerMenu open={mobileMenuOpen}>
            <div className='one'/><div className='two'/><div className='three'/>
          </BurgerMenu>
        </BurgerBubble>
      )}
    </>
  );
}

import styled, { keyframes } from 'styled-components';
import { absoluteFill, center, colors } from '../../styles';

const rotate = keyframes`
  0% {
    transform: none;
  }
  100% {
    transform: rotate(720deg);
  }
`;

export const Container = styled.div`
  ${center}
  position: ${props => props.fixed ? 'fixed' : 'absolute'};
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 100px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  pointer-events: none;
  animation: ${rotate} 6s cubic-bezier(.67,.23,.33,.76) infinite;
  > div {
    opacity: ${props => props.show ? 1 : 0};
  }
`;

export const Circle = styled.div`
  ${absoluteFill}
  transform: rotate(${props => props.deg}deg) translateX(20px);
  background-color: ${colors.main};
  /* outline: ${colors.main} solid 1px; */
  /* border: 4px white solid; */
  border-radius: 50%;
  z-index: -1;
  transition: opacity 0.5s ease-out;
`;

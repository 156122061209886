import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import AlbertRegular from './assets/fonts/AlbertSans-Regular.woff2';
import AlbertSemibold from './assets/fonts/AlbertSans-SemiBold.woff2';
import AlbertBold from './assets/fonts/AlbertSans-Bold.woff2';
import { mediaMax } from './common/breakpoints';

export const colors = {
  lightGray: '#bbb',
  darkGray: '#333',
  main: '#853BFF',
  accent: '#c7ffa9',
  // main: '#0000ff',
};

export const absoluteFill = css`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const center = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const centerVertically = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AlbertSans';
    font-weight: 400;
    src: url('${AlbertRegular}') format('woff2');
  }
  @font-face {
    font-family: 'AlbertSans';
    font-weight: 500;
    src: url('${AlbertSemibold}') format('woff2');
  }
  @font-face {
    font-family: 'AlbertSans';
    font-weight: 700;
    src: url('${AlbertBold}') format('woff2');
  }
  body {
    margin: 0;
    font-size: 14px;
    overflow-x: hidden;
    font-family: 'AlbertSans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* ${mediaMax('small',`
      font-size: 12px;
    `)} */
  }
  h1 {
    margin: 0;
    font-size: 30px;
    ${mediaMax('small',`
      font-size: 22px;
    `)}
  }
  h1, h2, h3 {
    color: ${colors.main};
  }
  p {
    margin-bottom: 20px;
  }
  a {
    color: ${colors.main};
  }
`;

export const AppContentContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-out forwards;
  position: relative;
  overflow-y: auto;
  height: 100%;
`;

export const AppContainer = styled.div`
  ${absoluteFill}
  padding: 40px 40px 40px 220px;
  ${mediaMax('small',`
    padding: 70px 0 0 0;
  `)}
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  ${centerVertically};
  color: ${colors.main};
  font-weight: 600;
  font-size: 20px;
`;

import styled from 'styled-components';
import { center } from '../../../styles';

export const Container = styled.div`
  position: relative;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Image = styled.img`
  ${center}
  max-width: 100%;
  max-height: 100%;
  opacity: ${props => props.loaded ? 1 : 0};
  transition: opacity 0.25s linear;
  `;

import React, { useState }  from 'react';
import { Container, Image } from './styles';
import Loader from '../../Loader';

export default function SliderImage({url, alt}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <Container>
      <Image src={url} onLoad={()=>{setLoaded(true)}} loaded={loaded} alt={alt} />
      <Loader show={!loaded} />
    </Container>
  );
}
import styled from 'styled-components';
import { mediaMax } from '../../common/breakpoints';

export const Container = styled.div`
  padding: 50px 100px 50px 0;
  ${mediaMax('small',`
    padding: 25px;
  `)}
`;

export const RichText = styled.div`
`;

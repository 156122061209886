import styled from 'styled-components';
// import { mediaMax } from '../../common/breakpoints';
import { colors, fadeIn } from '../../styles';
import { mediaMax } from '../../common/breakpoints';
export const columnAmountL = 4;
export const columnAmountM = 3;
export const columnAmountS = 2;

export const InstaIcon = ({color, height}) =>
  <span style={{height, display: "inline-block", marginRight: "8px", verticalAlign: "middle"}}>
    <svg width="100%" height={height+"px"} viewBox="0 0 20 16" version="1.1" style={{fillRule:"evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:"82"}}>
        <g transform="matrix(1,0,0,1,-65.4857,-3778.65)">
            <g transform="matrix(0.947917,0,0,0.907407,0,2863.51)">
                <g transform="matrix(1.76514,0,0,1.46599,-34.9836,-2134.65)">
                    <path d="M70.84,2147.03C70.84,2145.39 69.782,2144.06 68.478,2144.06L61.319,2144.06C60.015,2144.06 58.957,2145.39 58.957,2147.03L58.957,2152.97C58.957,2154.61 60.015,2155.94 61.319,2155.94L68.478,2155.94C69.782,2155.94 70.84,2154.61 70.84,2152.97L70.84,2147.03Z" style={{fill:"none"}}/>
                    <clipPath id="_clip1">
                        <path d="M70.84,2147.03C70.84,2145.39 69.782,2144.06 68.478,2144.06L61.319,2144.06C60.015,2144.06 58.957,2145.39 58.957,2147.03L58.957,2152.97C58.957,2154.61 60.015,2155.94 61.319,2155.94L68.478,2155.94C69.782,2155.94 70.84,2154.61 70.84,2152.97L70.84,2147.03Z"/>
                    </clipPath>
                    <g clipPath="url(#_clip1)">
                        <g transform="matrix(-1,0,0,-1,163.797,4297.06)">
                            <path d="M98.899,2147L107,2153L90.797,2153L98.899,2147Z" style={{fill:"none", stroke:color, strokeWidth:"0.99px"}}/>
                        </g>
                    </g>
                    <path d="M70.84,2147.03L70.84,2152.97C70.84,2154.61 69.782,2155.94 68.478,2155.94L61.319,2155.94C60.015,2155.94 58.957,2154.61 58.957,2152.97L58.957,2147.03C58.957,2145.39 60.015,2144.06 61.319,2144.06L68.478,2144.06C69.782,2144.06 70.84,2145.39 70.84,2147.03ZM69.944,2147.03C69.944,2146.01 69.287,2145.19 68.478,2145.19L61.319,2145.19C60.51,2145.19 59.853,2146.01 59.853,2147.03L59.853,2152.97C59.853,2153.99 60.51,2154.81 61.319,2154.81L68.478,2154.81C69.287,2154.81 69.944,2153.99 69.944,2152.97L69.944,2147.03Z" style={{fill: color}}/>
                </g>
            </g>
        </g>
    </svg>
  </span>
;

export const ContentContainer = styled.div`
  padding-bottom: 40px;
  ${mediaMax('small',`
    padding: 25px 25px 90px 25px;
  `)}
`;

export const ColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${columnAmountL}, 1fr);
  grid-gap: 20px;
  width: 100%;
  ${mediaMax('medium',`
    grid-template-columns: repeat(${columnAmountM}, 1fr);
    grid-gap: 15px;
  `)}
  ${mediaMax('small',`
    grid-template-columns: repeat(${columnAmountS}, 1fr);
    grid-gap: 12px;
    width: 100%;
  `)}
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-out forwards;
  animation-delay: 0.1s;
`;

export const Column = styled.div`
`;

export const Intro = styled.div`
  color: ${colors.main};
  margin: 80px 20px;
  animation: ${fadeIn} 0.5s ease-out forwards;
  h1 {
    font-weight: normal;
  }
  a {
    color: ${colors.main};
  }
  /* b {
    background-color: ${colors.accent};
  } */
  ${mediaMax('small',`
    margin: 35px 10px 60px;
  `)}
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  outline: 1px solid ${colors.main};
`;

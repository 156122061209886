import styled from 'styled-components';
import { center, colors } from '../../styles';
import { mediaMax } from '../../common/breakpoints';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  &:hover {
    .title-overlay{
      opacity: 1;
    }
  }
  ${mediaMax('medium',`
    margin-bottom: 12px;
  `)}
  ${mediaMax('small',`
    margin-bottom: 8px;
    border-radius: 3px;
  `)}
  padding-bottom: ${props => props.ratio * 100}%;
  background-color: ${colors.main};
  /* background-color: ${props => props.loaded ? 'transparent' : colors.main}; */
  /* transition: all 0.5s linear 0.25s; */
  /* outline: 1px solid ${colors.main}; */
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
`;

export const Image = styled.img`
  position: absolute;
  height: 100.5%;
  transition: opacity 0.5s ease-out ${props => props.index * 60}ms;
  opacity: ${props => props.loaded ? 1 : 0};
`;

export const Video = styled.video`
  position: absolute;
  width: 100%;
  /* min-height: 100%; */
  transition: opacity 0.5s ease-out ${props => props.index * 60}ms;
  opacity: ${props => props.loaded ? 1 : 0};
`;

export const Image2 = styled.img`
  ${center}
  height: 100%;
  transition: opacity 1s linear;
  opacity: ${props => (props.show && props.loaded) ? 1 : 0};
  pointer-events: none;
`;

export const TitleOverlay = styled.div`
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  border: 1px solid ${colors.main};
  color: ${colors.main};
  background-color: white;
  opacity: 0;
  font-size: 18px;
  border-radius: 1000px;
  width: max-content;
  max-width: 90%;
  pointer-events: none;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.15s ease-out;
`;

export const AmountContainer = styled.div`
  position: absolute;
  bottom: 6px;
  width: 100%;
  left: 0;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  opacity: 0.7;
`;

import styled from 'styled-components';
import { center } from '../../../styles';

export const Container = styled.div`
  position: relative;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Video = styled.video`
  ${center}
  max-width: 100%;
  max-height: 100%;
`;

import React, { useMemo } from 'react';
import { ContentContainer, Intro, ColumnGrid, Column, columnAmountS, columnAmountM, columnAmountL, InstaIcon } from './styles';
import ProjectTile from '../ProjectTile';
import { useWindowStore } from '../../services/WindowService';
import { Link, useNavigate } from 'react-router-dom';
import useDataStore, { emailAdress } from '../../services/DataService';
import { colors } from '../../styles';
import { useTrackingStore } from '../../services/TrackingService';

export default function ProjectGrid({tag}) {

  const projects = useDataStore(state => state.projects);
  const breakpoint = useWindowStore(state => state.breakpoint);
  const navigate = useNavigate();

  const filteredProjects = useMemo(()=>projects.filter(p => p.tags.split(' ').findIndex(t => t === tag) !== -1), [projects, tag])

  const columnAmount = (breakpoint === 'L') ? columnAmountL : (breakpoint === 'M') ? columnAmountM : columnAmountS;
  const projectAmount = filteredProjects.length;
  const columns = new Array(columnAmount);
  const columnHeights = new Array(columnAmount);
  for (let c = 0; c < columnAmount; c++) {columns[c] = []; columnHeights[c] = 0;}
  for (let p = 0; p < projectAmount; p++) {
    if (p < columnAmount) {
      columns[p].push(p);
      columnHeights[p] += filteredProjects[p].preview_images[0].height / filteredProjects[p].preview_images[0].width;
    } else {
      // find least filled column
      const minColumnHeight = Math.min(...columnHeights);
      const smallestColumn = columnHeights.indexOf(minColumnHeight);
      columns[smallestColumn].push(p);
      columnHeights[smallestColumn] += filteredProjects[p].preview_images[0].height / filteredProjects[p].preview_images[0].width;
    }
  }

  const onClick = (identifier)=>{
    useTrackingStore.getState().trackPageView(`/${tag}/${identifier}`, identifier);
    navigate(`/${tag}/${identifier}`);
  };

  return (
    <ContentContainer>
      {tag === 'home' && <Intro>
          <h1>Valentin Scheiner is a Berlin-based <b>Artist</b>, <b>Illustrator</b> & <b>Graphic Designer</b>.</h1>
          <p>
            He offers services such as <i>Editorial Illustration</i>, <i>Corporate Identity Design</i>, <i>Motion Design</i>, <i>Portrait Drawings</i> and <i>Social Media Content</i>.
            <br /><br />
            <Link to={`mailto: ${emailAdress}`}><InstaIcon color={colors.main} height={14} />Contact</Link>
          </p>
        </Intro>
      }
      <ColumnGrid>
          {columns.map((column, c) => {
            return (
              <Column key={c}>
                {column.map(pr => <ProjectTile key={filteredProjects[pr].identifier} project={filteredProjects[pr]} onClick={onClick} index={c}/>)}
              </Column>
            );
          })}
      </ColumnGrid>
    </ContentContainer>
  );
}

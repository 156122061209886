import create from '../utilities/zustand/create';
import superagent from 'superagent';

export const emailAdress = 'contact@valentinscheiner.de'

const REACT_APP_BASE_URL = 'https://backend.valentinscheiner.de'
const API_URL = REACT_APP_BASE_URL + '/api';
const xApiKey = 'anuRNK47IOnoJc0pJCM6O';

const username = 'admin';
const pass = 'd1nVPfaAP!';

const useDataStore = create(module, (set, get) => ({
  jwt: undefined,
  projects: undefined,
  impressum: undefined,
  datenschutz: undefined,
  authorize: () => {
    superagent.post(API_URL + '/auth')
              .set('x-api-key', xApiKey)
              .set('authorization', 'Basic '+btoa(username + ':' + pass))
              .end((err, res) => {
                console.log('res', res);
                if (err) console.log("Errors while authorizing: ", err);
                set({jwt: res.body.jwt});
              });
  },
  getAllProjects: (jwt) => {
    superagent.get(API_URL + '/projects')
      .set('x-api-key', xApiKey)
      // .set('authorization', 'Bearer '+jwt)
      .end((err, res) => {
        if (err) console.log("Errors while getting projects: ", err);
        console.log('res', res.body);
        set({projects: res.body.projects, impressum: res.body.impressum, datenschutz: res.body.datenschutz});
      });
  }
}));

export default useDataStore;

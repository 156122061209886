import styled from 'styled-components';
import { mediaMin } from '../../common/breakpoints';
import { absoluteFill, centerVertically, colors } from '../../styles';

export const Container = styled.div`
  ${absoluteFill}
  position: fixed;
  ${mediaMin('medium',`
    display: none;
  `)}
  background-color: ${colors.main};
`;

export const MobileMenuContainer = styled.div`
  ${centerVertically}
  display: inline-block;
  left: 0;
  width: 100%;
  text-align: center;
`;

export const MenuItem = styled.div`
  margin-bottom: 10px;
  a {
    color: white;
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
    font-size: 25px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  text-align: center;
  bottom: 35px;
  left: 0;
  width: 100%;
  > div > a {
    color: white;
    font-size: 10px;
    text-decoration: none;
    opacity: 0.7;
  }
`;

export const Icon = styled.img`
  height: 22px;
  margin: 0 6px;
`;
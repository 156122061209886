import React, { useEffect, useMemo, useState } from 'react';
import { ImageContainer, Image, TitleOverlay, Image2, AmountContainer, Video } from './styles';

export default function ProjectTile({project, onClick, index}) {

  const [loaded, setLoaded] = useState(false);
  const [secondImageLoaded, setSecondImageLoaded] = useState(false);
  const [showSecondImage, setShowSecondImage] = useState(false);
  const ratio = project.preview_images[0].height / project.preview_images[0].width;

  const hasMultiplePreviews = project && project.preview_images && project.preview_images.length > 1;

  useEffect(() => {
    let intervalId;
    if(hasMultiplePreviews) {
      intervalId = setInterval(() => {
        setShowSecondImage(!showSecondImage);
      }, 3000+Math.random()*5000);
    }
      return () => {
        if(intervalId) clearInterval(intervalId);
      };
  }, [hasMultiplePreviews, showSecondImage]);

  const amountText = useMemo(()=>{
    let res = project.videos.length > 0 ? project.videos.length + ' video' : '';
    if (project.videos.length > 1) res += 's';
    if (project.images.length > 0) {
      if (res !== '') res += ' + ';
      res += project.images.length + ' image';
      if (project.images.length > 1) res += 's';
    }
    return res;
  }, [project]);

  return (
    <ImageContainer ratio={ratio} loaded={loaded}>
      {!project.preview_video && <Image src={project.preview_images[0].url} onLoad={()=>{setLoaded(true)}} onClick={()=>{onClick(project.identifier)}} loaded={loaded} index={index} alt={project.title + ' thumbnail'}/>}
      {/* {project.preview_video && <Video src={project.preview_video} onClick={()=>{onClick(project.identifier)}} onLoadedData={()=>{setLoaded(true)}} loaded={loaded} index={index} muted loop onMouseOver={event => event.target.play()} onMouseOut={event => event.target.pause()}/>} */}
      {project.preview_video && <Video src={project.preview_video} onClick={()=>{onClick(project.identifier)}} onLoadedData={()=>{setLoaded(true)}} loaded={loaded} index={index} muted loop autoPlay />}
      {project.preview_images.length > 1 && <Image2 src={project.preview_images[1].url} show={showSecondImage} loaded={secondImageLoaded} onLoad={()=>{setSecondImageLoaded(true)}}/>}
      <TitleOverlay className='title-overlay'>
        {project.title}
        <AmountContainer>{amountText}</AmountContainer>
      </TitleOverlay>
    </ImageContainer>
  );
}